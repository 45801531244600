import React, { useEffect, useState } from "react";

import RecipeLayout from "@/components/recipes/RecipeLayout.tsx";

import { Button } from "@/components/ui/button.tsx";

import { ChevronDown } from "lucide-react";

import { Recipe } from "@/firebase/types";

type Props = {
  recipe: Partial<Recipe>
}

// Used to have functionality for sticking the scroll to the bottom while a recipe loads in
// Keeping this component for now, but isn't technically useful
export default function RecipeLoading({ recipe }: Props) {
  return (
    <>
      <RecipeLayout recipe={recipe} isLoading={true} />
    </>
  )
}
import { useEffect, useRef, useState } from "react";

import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase/app.ts";
import { uuidv4 } from "@firebase/util";

import base64ImageToFile from "@/lib/helpers/base64ImageToFile.ts";
import { uploadRecipeImage } from "@/lib/images.ts";

import { parse } from "best-effort-json-parser";

import { Recipe } from "@/firebase/types";

type GeneratedRecipeType = Pick<Recipe,
  "id" | "title" | "description" | "image" | "ingredients" | "instructions" | "prepTime" | "cookTime" | "totalTime" |
  "servings" | "keywords" | "category" | "cuisine" | "nutrition"
>
type SocialMediaPostInfo = {
  description: string | null
  imageUrl: string | null
  imageBase64: string | null
  closedCaptions: string | null
}

// Fetches the social media post info we need from a worker
// Streams a recipe based on social media post from another worker
// At the same time, uploads the image received from the first worker (the social media post cover image) to use as the recipe image
export default function useStreamGeneratedRecipeFromSocialMedia(fields: { url: string }, isSubscribed = false) {
  const [streamedRecipe, setStreamedRecipe] = useState<GeneratedRecipeType | null>(null)
  const [recipeImage, setRecipeImage] = useState<Recipe["image"] | null>(null)
  const [recipeError, setRecipeError] = useState<any | null>(null)
  const [imageError, setImageError] = useState<any | null>(null)

  // The social media post couldn't be used to make an edible recipe (is probably a post of something inedible)
  const [cannotUsePost, setCannotUsePost] = useState<any | null>(false)

  const isStreaming = useRef(false)
  const isUploadingImage = useRef(false)
  const [isComplete, setIsComplete] = useState(false)

  const recipeId = useRef(uuidv4())

  const incrementFreeRecipeSocialMediaImportsFunc = httpsCallable(functions, 'incrementFreeRecipeSocialMediaImports')

  // Start streaming the recipe when this hook is called
  useEffect(() => {
    if(!isStreaming.current) {
      streamGeneratedRecipe()
    }
  }, [])

  // Once the recipe generation is complete, increment the number of free social media imports on the user - only if they're not a premium user and nothing went wrong
  useEffect(() => {
    if(!isSubscribed && isComplete && !recipeError && !cannotUsePost) {
      incrementFreeRecipeSocialMediaImportsFunc()
    }
  }, [isComplete])

  async function fetchSocialMediaPostInfo() {
    try {
      const res = await fetch(import.meta.env.VITE_FETCH_SOCIAL_MEDIA_POST_WORKER, {
        method: "POST",
        body: JSON.stringify({ url: fields.url })
      })
      const data: SocialMediaPostInfo = await res.json()

      return data
    } catch(e) {
      console.error(e)

      setRecipeError(e)
    }
  }

  async function streamGeneratedRecipe() {
    try {
      isStreaming.current = true

      const postInfo = await fetchSocialMediaPostInfo()

      const res = await fetch(import.meta.env.VITE_GENERATE_RECIPE_FROM_SOCIAL_MEDIA_WORKER, {
        method: "POST",
        body: JSON.stringify(postInfo)
      })

      if(!res.ok) {
        throw new Error("Something went wrong while fetching recipe from worker")
      }

      if(res.body) {
        let reader = res.body.getReader();
        let result;
        let decoder = new TextDecoder('utf8');

        let recipeStream = ""

        while(!result?.done) {
          result = await reader.read();
          let chunk = decoder.decode(result.value);

          recipeStream += chunk

          // Try to update the recipe JSON object
          try {
            const parsedRecipe = parse(recipeStream)
            setStreamedRecipe(parsedRecipe)
          } catch(e) {
            console.error(e)
            // If it fails, no worries, the next chunk will probably be successful, just don't error out
          }
        }

        if(result.done) {
          isStreaming.current = false

          try {
            const parsedRecipe = parse(recipeStream)
            if(parsedRecipe?.error) {
              setCannotUsePost(true)
              setIsComplete(true)
            } else {
              // Upload the image once we have confirmation this post is acceptable to use for a recipe
              if(postInfo && postInfo.imageBase64) {
                await uploadImage(postInfo.imageBase64)
              } else {
                setIsComplete(true)
              }
            }
          } catch(e) {
            console.error(e)
            // Ignore
          }
        }
      }
    } catch(e: any) {
      console.error(e)

      setRecipeError(e)
      isStreaming.current = false
      setIsComplete(true)
    }
  }

  async function uploadImage(base64Image) {
    try {
      isUploadingImage.current = true

      const imageFile = await base64ImageToFile(base64Image, 'social-media-image')

      const uploadedImage = await uploadRecipeImage(recipeId.current, imageFile)

      if(uploadedImage) {
        setRecipeImage(uploadedImage)
      }

      isUploadingImage.current = false

      setIsComplete(true)
    } catch(e: any) {
      console.error(e)

      setImageError(e)
      isUploadingImage.current = false

      setIsComplete(true)
    }
  }

  return {
    streamedRecipe: {
      id: recipeId.current,
      ...streamedRecipe,
      image: recipeImage,
    } as GeneratedRecipeType,
    isComplete,
    recipeError,
    imageError,
    cannotUsePost
  }
}
import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import { analytics, auth, db } from "@/firebase/app.ts";
import { useAuthState } from "react-firebase-hooks/auth";
import { addDoc, collection, getDocs, limit, onSnapshot, query, where } from "firebase/firestore";
import { logEvent } from "firebase/analytics";

import Loading from "@/components/page/Loading.tsx";
import ErrorLoading from "@/components/page/ErrorLoading.tsx";

import { Badge } from "@/components/ui/badge.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

import LogoImg from "@/assets/icon.svg";
import DeliciousImg from "@/assets/images/delicious.png"

import { ArrowLeft, Check, Loader2 } from "lucide-react";

export default function Web() {
  const { t } = useTranslation()
  const { toast } = useToast()

  const [product, setProduct] = useState<any | null>(null)
  const [prices, setPrices] = useState<any | null>(null)
  const [selectedPrice, setSelectedPrice] = useState<string | null>(null)

  const [loadingCheckoutUrl, setLoadingCheckoutUrl] = useState(false)

  const [user, loadingUser, errorUser] = useAuthState(auth)

  const [erroredOut, setErroredOut] = useState(false)

  useEffect(() => {
    (async function() {
      try {
        let env = import.meta.env.VITE_ENV
        if(env === 'local') env = 'development' // Account for local development

        const querySnapshot = await getDocs(query(
          collection(db, "stripeProducts"),
          where('active', '==', true),
          where('metadata.env', '==', env),
          limit(1)
        ))

        const product = {
          id: querySnapshot.docs[0].id,
          ...querySnapshot.docs[0].data()
        }

        const pricesQuerySnapshot = await getDocs(query(
          collection(db, "stripeProducts", product.id, "prices"),
          where('active', '==', true)
        ))

        const prices: any[] = []

        pricesQuerySnapshot.forEach(doc => {
          prices.push({
            id: doc.id,
            ...doc.data()
          })
        })

        setProduct(product)
        // Reverse order so the yearly plan appears first
        setPrices(prices.reverse())
        setSelectedPrice(prices[0].id)
      } catch(error) {
        console.error(error)

        setErroredOut(true)
      }
    })()
  }, [])

  async function startCheckout() {
    if(user) {
      setLoadingCheckoutUrl(true)

      const checkoutSessionRef = collection(db, "stripeCustomers", user.uid, "checkout_sessions")

      const sessionDoc = await addDoc(checkoutSessionRef, {
        price: selectedPrice,
        automatic_tax: true,
        success_url: window.location.origin + '/subscription/upgrade/success',
        cancel_url: window.location.origin + window.location.pathname,
        allow_promotion_codes: true
      })

      onSnapshot(sessionDoc, (snap) => {
        // @ts-ignore
        const { error, url } = snap.data();
        if(error) {
          setLoadingCheckoutUrl(false)

          toast({
            title: t("subscription.upgrade.components.Web.startCheckout.error.generic.title"),
            description: t("subscription.upgrade.components.Web.startCheckout.error.generic.description"),
            variant: "destructive"
          })
        }
        if(url) {
          setLoadingCheckoutUrl(false)

          logEvent(analytics, 'subscribe_button_click')

          window.location.assign(url);
        }
      })
    }
  }

  if((product === null || prices === null || loadingUser) && !erroredOut && !errorUser) {
    return <Loading />
  }

  if(erroredOut || errorUser) {
    return <ErrorLoading />
  }

  return (
    <div className="lg:flex lg:items-center lg:flex-grow lg:gap-8 lg:max-w-7xl lg:mx-auto">
      <div className="relative flex flex-col justify-center items-center max-h-60 sm:max-h-96 lg:max-h-none overflow-hidden">
        <div className="absolute left-0 top-0 right-0 w-full p-2 lg:p-4">
          <Button
            type="button"
            variant="background"
            size="icon"
            asChild
            className="z-10"
            aria-label={t('subscription.upgrade.components.Web.header.goBackButton.label')}
          >
            <Link to="/account">
              <ArrowLeft size={20} />
            </Link>
          </Button>
        </div>
        <img
          src={DeliciousImg}
          alt={t('subscription.upgrade.components.Web.header.image.alt')}
          className="object-cover object-center w-full h-full"
        />
      </div>

      <div className="max-w-2xl mx-auto lg:min-w-[40%] lg:max-w-md px-4 py-4 lg:px-8">
        <div className="flex items-center mb-4">
          <img
            src={LogoImg}
            alt="Flavorish"
            className="w-8 mr-4"
          />
          <h2 className="font-poppins text-2xl font-semibold">{t('subscription.upgrade.components.Web.title')}</h2>
        </div>

        <p className="text-lg font-semibold mb-2">{t('subscription.upgrade.components.Web.subtitle')}</p>

        <ul className="flex flex-col gap-4 mb-8">
          <li className="flex gap-2">
            <Check
              size={24}
              strokeWidth={3}
              className="flex-shrink-0 text-primary"
            />
            <p>
              {t('subscription.upgrade.components.Web.benefits.1')}
            </p>
          </li>
          <li className="flex gap-2">
            <Check
              size={24}
              strokeWidth={3}
              className="flex-shrink-0 text-primary"
            />
            <p>
              {t('subscription.upgrade.components.Web.benefits.2')}
            </p>
          </li>
          <li className="flex gap-2">
            <Check
              size={24}
              strokeWidth={3}
              className="flex-shrink-0 text-primary"
            />
            <p>
              {t('subscription.upgrade.components.Web.benefits.3')}
            </p>
          </li>
          <li className="flex gap-2">
            <Check
              size={24}
              strokeWidth={3}
              className="flex-shrink-0 text-primary"
            />
            <p>
              {t('subscription.upgrade.components.Web.benefits.4')}
            </p>
          </li>
        </ul>

        {product && prices && (
          <>
            <div className="flex flex-col gap-2 mb-8">
              {prices.map((currPrice) => (
                <Button
                  key={currPrice.id}
                  type="button"
                  variant="outline"
                  className={`block w-full h-auto text-wrap ${selectedPrice === currPrice.id ? 'border-primary' : ''}`}
                  onClick={() => setSelectedPrice(currPrice.id)}
                >
                  <div className="flex justify-between items-center gap-4 mb-2">
                    <div className="flex items-center gap-2">
                      <div className={`flex justify-center items-center w-6 h-6 ${selectedPrice === currPrice.id ? 'bg-primary' : 'bg-muted'} rounded-full`}>
                        {selectedPrice === currPrice.id && (
                          <Check
                            size={18}
                            strokeWidth={3}
                            className="flex-shrink-0 text-primary-foreground"
                          />
                        )}
                      </div>

                      <p className="text-lg font-semibold">
                        {/* Price is stored in cents */}
                        ${currPrice.unit_amount / 100}/{currPrice.interval}
                      </p>
                    </div>

                    {currPrice.interval === "year" && (
                      <Badge variant="primary">
                        {t('subscription.upgrade.components.Web.offerings.annualPlanSavings')}
                      </Badge>
                    )}
                  </div>

                  <div>
                    <p className="text-left">{t('subscription.upgrade.components.Web.offerings.description')}</p>
                  </div>
                </Button>
              ))}
            </div>

            <Button
              type="button"
              size="lg"
              variant="primary"
              className="w-full"
              onClick={startCheckout}
              disabled={loadingCheckoutUrl}
            >
              {loadingCheckoutUrl ? (
                <Loader2 size={24} className="animate-spin" />
              ) : t('subscription.upgrade.components.Web.actions.subscribe')}
            </Button>

            <p className="my-8 text-xs text-neutral dark:text-neutral-content/60">
              <Trans i18nKey="subscription.upgrade.components.Web.legalText">
                Subscriptions will be applied to your Google account on confirmation. Subscriptions will
                automatically renew unless canceled within 24-hours before the end of the current period.
                You can cancel anytime with your Google account settings. For more information, see our
                <Link to="https://www.flavorish.ai/terms-of-use" target="_blank" className="link">terms of use</Link>
                and
                <Link to="https://www.flavorish.ai/privacy-policy" target="_blank" className="link">privacy policy</Link>.
              </Trans>
            </p>
          </>
        )}
      </div>
    </div>
  )
}
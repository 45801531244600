import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"

import { auth, db } from "@/firebase/app"
import { getDocs, collection, limit, orderBy, query, where, startAfter } from "firebase/firestore"
import { useAuthState } from "react-firebase-hooks/auth"
import { useCollectionDataOnce } from "react-firebase-hooks/firestore"

import useIsMobile from "@/hooks/useIsMobile.tsx";

import Page from "@/components/page/Page.tsx";
import Loading from "@/components/page/Loading.tsx"
import ErrorLoading from "@/components/page/ErrorLoading.tsx"

import NoRecipes from "@/routes/recipes/components/general/NoRecipes.tsx";
import RecipesHeader from "@/routes/recipes/components/general/RecipesHeader.tsx";
import AddRecipe from "@/routes/recipes/components/AddRecipe.tsx";
import RecipeCard from "@/routes/recipes/components/RecipeCard.tsx";

import InfiniteScroll from "@/components/ui/expansions/infinite-scroll.tsx";

import { Loader2 } from "lucide-react";

import { Recipe } from "@/firebase/types"

const PAGE_LIMIT = 10

export default function RecipesPage() {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const [user, loadingUser, errorUser] = useAuthState(auth)

  const [recipesData, loading, error] = useCollectionDataOnce(
    query(
      collection(db, "recipes"),
      where("userId", "==", user?.uid),
      orderBy("_created", "desc"),
      limit(PAGE_LIMIT)
    )
  )

  const [recipes, setRecipes] = useState<Recipe[] | null>(null)

  const [canFetchMore, setCanFetchMore] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)

  useEffect(() => {
    if(recipesData && !loading && !error) {
      setRecipes(recipesData as Recipe[])

      if(recipesData.length >= PAGE_LIMIT) {
        setCanFetchMore(true)
      }
    }
  }, [recipesData])

  async function next() {
    if(recipes) {
      setLoadingMore(true)

      const querySnapshot = await getDocs(query(
        collection(db, "recipes"),
        where("userId", "==", user?.uid),
        orderBy("_created", "desc"),
        startAfter(recipes[recipes.length - 1]._created),
        limit(PAGE_LIMIT)
      ))

      const newRecipes: Recipe[] = []
      querySnapshot.forEach(doc => {
        newRecipes.push(doc.data() as Recipe)
      })

      setRecipes([...recipes, ...newRecipes])

      if(newRecipes.length < PAGE_LIMIT) {
        setCanFetchMore(false)
      }

      setLoadingMore(false)
    }
  }

  // Second param in this condition fixes an issue where on initial load, there's a moment after the recipesData has finished loading (loading = false)
  // but the useEffect hasn't set the recipes state variable yet, leaving the page to render the NoRecipes component
  if(loading || (!recipes && !loading && recipesData)) {
    return <Loading />
  }

  if(error) {
    return <ErrorLoading />
  }

  return (
    <Page>
      <Helmet>
        <title>{t("recipes.page.head.title")}</title>
      </Helmet>

      <RecipesHeader hasRecipes={!!(recipes && recipes?.length > 0)} />

      {recipes && recipes?.length > 0 ? (
        <div className="w-full max-w-7xl mx-auto mb-20 px-4">
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-2 gap-y-6">
            {recipes?.map((recipe) => (
              <RecipeCard key={recipe.id} recipe={recipe} />
            ))}
          </div>

          <InfiniteScroll hasMore={canFetchMore} isLoading={loadingMore} next={next} threshold={1}>
            {canFetchMore && (
              <div className="flex justify-center items-center my-16">
                <Loader2 size={48} className="animate-spin" />
              </div>
            )}
          </InfiniteScroll>
        </div>
      ) : (
        <NoRecipes />
      )}

      {isMobile && (
        <AddRecipe />
      )}
    </Page>
  )
}
